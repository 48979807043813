import React, { useRef, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import emailjs from "emailjs-com"
import {
  BsFillGeoAltFill,
  BsEnvelopeOpenFill,
  BsGlobe,
  BsFillTelephoneFill,
} from "react-icons/bs"
import Layout from "../components/layout"
import paint_bg from "../../public/static/images/white-paint-bg.jpg"

import "./styles/contact-us.css"
import { SocialIcon } from "react-social-icons"

function ContactUs() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialLinks {
            name
            link
          }
          contactDetails {
            address
            email
            website
            mobile
          }
        }
      }
    }
  `)

  const contactDetails = data.site.siteMetadata.contactDetails
  const socialLinks = data.site.siteMetadata.socialLinks
  const form = useRef()

  const [result, showResult] = useState(false)

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_84kx3og",
        "template_ttyv6bn",
        e.target,
        "7pbXFPRaSeewXqGQW"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
    showResult(true)
  }

  setTimeout(() => {
    showResult(false)
  }, 5000)

  return (
    <Layout>
      <section id="contact-us" style={{ backgroundImage: `url(${paint_bg})` }}>
        <Container className="contact-us-container">
          <Row>
            <Col className="col1" md={6}>
              <h3>Send us a message</h3>
              <Form ref={form} onSubmit={sendEmail}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="label">
                    Enter Name <span> * </span>
                  </Form.Label>
                  <Form.Control type="text" name="from_name" required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="label">
                    Email address <span> * </span>
                  </Form.Label>
                  <Form.Control type="email" name="from_email" required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="label">Subject</Form.Label>
                  <Form.Control type="text" name="subject" />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="label">
                    Message <span> * </span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    required
                  />
                </Form.Group>

                <Button
                  className="send_msg text-uppercase"
                  variant="success"
                  type="submit"
                >
                  Send Message
                </Button>

                <Row
                  style={{
                    margin: "0",
                    alignItems: "center",
                    marginTop: "16px",
                    borderLeft: "3px solid green",
                  }}
                >
                  {result ? (
                    <p style={{ margin: "0", padding: "10px" }}>
                      Message Sent!
                    </p>
                  ) : (
                    <></>
                  )}
                </Row>
              </Form>
            </Col>

            <Col className="col2" md={6}>
              <h3>Let's Connect</h3>

              <table>
                <tbody>
                  <tr>
                    <td>
                      <BsFillGeoAltFill id="icon" />
                    </td>

                    <td>{contactDetails.address}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsEnvelopeOpenFill id="icon" />
                    </td>

                    <td>{contactDetails.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsGlobe id="icon" />
                    </td>

                    <td>{contactDetails.website}</td>
                  </tr>
                  <tr>
                    <td>
                      <BsFillTelephoneFill id="icon" />
                    </td>

                    <td>{contactDetails.mobile}</td>
                  </tr>
                </tbody>
              </table>
              <h3>Social Links</h3>

              <ul className="list-unstyled">
                {socialLinks.map(link => (
                  <li>
                    <a target="_blank" rel="noopener" href={link.link}>
                      <SocialIcon
                        fgColor="#fff"
                        className="social_icon"
                        url={link.link}
                      />
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default ContactUs
